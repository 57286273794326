import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';

import * as Sellers from '../sellers';
import * as Teams from '../teams';

export enum ShiftPaymentMethodCountedAmountType {
  CASH = 'CASH',
  CUSTOM_PAYMENT_METHOD = 'CUSTOM_PAYMENT_METHOD',
}

export type Device = z.infer<typeof Device.schema>;
export namespace Device {
  export const _type = 'pos.device' as const;

  export const schema = z.object({
    _type: z.literal(_type).default(_type),
    id: z
      .string()
      .uuid()
      .default(() => uuidv4()),
    sellerId: z.string().uuid(),
    sellerLocationId: z.string().uuid(),
    deviceId: z.string(), // https://github.com/react-native-device-info/react-native-device-info#getuniqueid, not a UUID
    deviceNumber: z.number(),
    // OS-defined of the device, eg. Zames' iPad
    deviceName: z.string().nullable(),
    deviceType: z.string().nullable(),
    deviceBrand: z.string().nullable(),
    deviceModel: z.string().nullable(),
    deviceSystemVersion: z.string().nullable(),
    batteryLevel: z.number().nullable(),
    isBatteryCharging: z.boolean().nullable(),
    networkConnectionType: z.string().nullable(),
    networkSsid: z.string().nullable(),
    networkIpAddress: z.string().nullable(),
    // Computed field, <alias> ?? '<deviceName> <deviceNumber>' ?? 'Device <deviceNumber>'
    name: z.string(),
    shouldAutomaticallyAcceptOrders: z.boolean().default(false),
    appVersion: z.string().nullable(),
    createdAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
    updatedAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString()),
    isDeleted: z.boolean().nullable().default(false),
  });

  export const create = (args: Partial<Device> | any): Device => {
    return schema.parse({
      ...args,
      _type: _type,
      id: args.id ?? args.sellerDeviceId,
    });
  };
}

export type DeviceCreateParams = z.infer<typeof DeviceCreateParams.schema>;
export namespace DeviceCreateParams {
  export const schema = z.object({
    sellerLocationId: z.string().uuid(),
    deviceName: z.string().nullish(),
    shouldAutomaticallyAcceptOrders: z.boolean().nullish(),
  });
}

export type DeviceUpdateParams = z.infer<typeof DeviceUpdateParams.schema>;
export namespace DeviceUpdateParams {
  export const schema = z.object({
    sellerId: z.string().uuid().nullish(),
    sellerLocationId: z.string().uuid().nullish(),
    deviceId: z.string().nullish(),
    deviceName: z.string().nullish(),
    appVersion: z.string().nullish(),
    config: z.any(),
    views: z.any(),
    shouldAutomaticallyAcceptOrders: z.boolean().nullish(),
    updatedAt: z
      .string()
      .datetime({ offset: true })
      .default(() => new Date().toISOString())
      .nullish(),
  });
}

export type ShiftCashMovement = z.infer<typeof ShiftCashMovement.schema>;
export namespace ShiftCashMovement {
  export const schema = z.object({
    id: z.string().uuid(),
    amount: z.number(),
    note: z.string().nullable().default(null),
    createdBy: z.string().uuid(),
    createdByTeamMemberId: z.string().uuid().nullable().default(null), // TODO: Remove nullable once db is backfilled with data
    createdAt: z
      .string()
      .datetime({ offset: true })
      .default(new Date().toISOString()),
  });

  export const create = (
    args: Partial<ShiftCashMovement> | any,
  ): ShiftCashMovement => {
    return schema.parse({
      ...args,
      id: args.id ?? args.sellerRegisterCashMovementId,
    });
  };
}

export type ShiftPaymentMethodCountedAmount = z.infer<
  typeof ShiftPaymentMethodCountedAmount.schema
>;
export namespace ShiftPaymentMethodCountedAmount {
  export const schema = z.object({
    paymentMethodId: z.string(), // TODO: rename to brand
    countedAmount: z.number().nullable(), // Null if the shift is not closed

    type: z.nativeEnum(ShiftPaymentMethodCountedAmountType),
    paymentMethodName: z.string(),
    customPaymentMethodId: z.string().nullable().default(null), // Present if type === 'CUSTOM_PAYMENT_METHOD'
  });
}

/**
 * Shift
 */

export type Shift = z.infer<typeof Shift.schema> & {
  device?: Device;
};
export namespace Shift {
  export const _type = 'pos.shift' as const;

  export const schema = z.object({
    _type: z.literal(_type).default(_type),
    id: z.string().uuid(),
    sellerId: z.string().uuid(),
    sellerLocationId: z.string().uuid(),
    sellerDeviceId: z.string().uuid(),

    paymentMovements: z.array(ShiftCashMovement.schema).default([]), // TODO: Rename to cashMovements
    // The actual payment amount counted by the cashier, empty if the shift has not ended
    paymentMethodCountedAmounts: z
      .array(ShiftPaymentMethodCountedAmount.schema)
      .default([]), // TODO: 1. Separate out cash from customPaymentMethod counted amounts, 2: Only store counted amounts here -- Expected amounts should be on the report only
    note: z.string().nullable().default(null),

    startedBy: z.string().uuid(),
    startedByTeamMemberId: z.string().uuid().nullable().default(null), // TODO: Remove nullable once db is backfilled with data
    startedAt: z
      .string()
      .datetime({ offset: true })
      .default(new Date().toISOString()),
    endedBy: z.string().uuid().nullable().default(null),
    endedByTeamMemberId: z.string().uuid().nullable().default(null), // TODO: Remove nullable once db is backfilled with data
    endedAt: z.string().datetime({ offset: true }).nullable().default(null),
  });

  export const create = (
    args: (Partial<Shift> & Pick<Shift, 'paymentMovements'>) | any,
  ): Shift => {
    return schema.parse({
      ...args,
      _type: _type,
      id: args.id ?? args.sellerRegisterShiftId,
      paymentMovements: args.paymentMovements.map(ShiftCashMovement.create),
    });
  };
}

export type ShiftReportPaymentsSummary = z.infer<
  typeof ShiftReportPaymentsSummary.schema
>;
export namespace ShiftReportPaymentsSummary {
  export const schema = z.object({
    cash: z.object({
      brand: z.string(),
      paymentMethodName: z.string(),
      // count: number; // TODO: Currently cannot easily retrieve count because 1 cash payment + 1 cash refund = 2 payments, which is wrong!
      expectedAmount: z.number(),
      countedAmount: z.number(), // Zero if the shift hasn't ended
    }),
    customPaymentMethods: z
      .array(
        z.object({
          customPaymentMethodId: z.string().uuid(),
          brand: z.string(),
          paymentMethodName: z.string(),
          count: z.number(),
          expectedAmount: z.number(),
          countedAmount: z.number(), // Zero if the shift hasn't ended
        }),
      )
      .default([]),
    integratedPaymentMethods: z
      .array(
        z.object({
          name: z.string(),
          count: z.number(),
          amount: z.number(),
        }),
      )
      .default([]),
  });
}

export type ShiftReportCashDrawer = z.infer<
  typeof ShiftReportCashDrawer.schema
>;
export namespace ShiftReportCashDrawer {
  export const schema = z.object({
    startingCash: z.number(),
    movementsAmount: z.number(),
    cashSalesRevenue: z.number(),
    expectedAmount: z.number(),
    countedAmount: z.number(),
    cashVariance: z.number(),
    cashMovements: z.array(ShiftCashMovement.schema).default([]),
  });
}

// export interface ShiftReportItemSalesByCategory {
//   totalQuantity: number;
//   totalRevenue: number;
//   categories: {
//     categoryId: string | null;
//     name: string;
//     quantity: number;
//     revenue: number;
//     items: {
//       uid: string;
//       itemId: string; // TODO: Deprecate
//       name: string;
//       quantity: number;
//       revenue: number;
//       itemVariations: {
//         itemVariationId: string;
//         name: string;
//         quantity: number;
//         revenue: number;
//       }[];
//     }[];
//   }[];

export type ShiftReportItemSalesByCategory = z.infer<
  typeof ShiftReportItemSalesByCategory.schema
>;
export namespace ShiftReportItemSalesByCategory {
  export const schema = z.object({
    totalQuantity: z.number(),
    totalRevenue: z.number(),
    categories: z
      .array(
        z.object({
          categoryId: z.string().uuid().nullable(),
          name: z.string(),
          quantity: z.number(),
          revenue: z.number(),
          items: z
            .array(
              z.object({
                uid: z.string().uuid(),
                itemId: z.string().uuid(), // TODO: Deprecate
                name: z.string(),
                quantity: z.number(),
                revenue: z.number(),
                itemVariations: z
                  .array(
                    z.object({
                      itemVariationId: z.string().uuid(),
                      name: z.string(),
                      quantity: z.number(),
                      revenue: z.number(),
                    }),
                  )
                  .default([]),
              }),
            )
            .default([]),
        }),
      )
      .default([]),
  });
}

export type ShiftReportRefundHistory = z.infer<
  typeof ShiftReportRefundHistory.schema
>;
export namespace ShiftReportRefundHistory {
  export const schema = z.object({
    total: z.number(),
    refunds: z.array(
      z.object({
        createdAt: z
          .string()
          .datetime({ offset: true })
          .default(() => new Date().toISOString()),
        amount: z.number(),
      }),
    ),
  });
}

export type ShiftReportVoucherRedemptions = z.infer<
  typeof ShiftReportVoucherRedemptions.schema
>;
export namespace ShiftReportVoucherRedemptions {
  export const schema = z.object({
    totalAmount: z.number(),
    vouchers: z
      .array(
        z.object({
          voucherId: z.string().uuid(),
          name: z.string(),
          amount: z.number(),
          count: z.number(),
          saleVouchers: z
            .array(
              z.object({
                saleVoucherId: z.string().uuid(),
                note: z.string(),
                amount: z.number(),
                createdAt: z
                  .string()
                  .datetime({ offset: true })
                  .default(() => new Date().toISOString()),
              }),
            )
            .default([]),
        }),
      )
      .default([]),
  });
}

/**
 * ShiftReport
 */

export type ShiftReport = z.infer<typeof ShiftReport.schema>;
export namespace ShiftReport {
  export const schema = z.object({
    sellerRegisterShift: Shift.schema,
    header: z.object({
      sellerName: z.string(),
      sellerDeviceName: z.string(),
      sellerLocationName: z.string(),
      startedAt: z
        .string()
        .datetime({ offset: true })
        .default(() => new Date().toISOString()),
      endedAt: z.string().datetime({ offset: true }).nullable().default(null),
      note: z.string().nullable().default(null),
    }),
    sales: z.object({
      grossCollectionAmount: z.number(),
      addOnsAmount: z.number(),
      discountsAmount: z.number(),
      extraChargesAmount: z.number(),
      taxesAmount: z.number(),
      cashRoundingAdjustmentAmount: z.number(),
      totalReceivableAmount: z.number(),
      excessCollectionAmount: z.number(),
      saleCount: z.number(),
      averageSpendPerSale: z.number(),
    }),
    paymentsSummary: ShiftReportPaymentsSummary.schema,
    cashDrawer: ShiftReportCashDrawer.schema,
    itemSalesByCategory: ShiftReportItemSalesByCategory.schema,
    refundHistory: ShiftReportRefundHistory.schema,
    voucherRedemptions: ShiftReportVoucherRedemptions.schema,
  });
}

export type Session = z.infer<typeof Session.schema>;
export namespace Session {
  export const schema = z.object({
    sellerUserProfile: Sellers.SellerUser.schema,
    seller: Sellers.Seller.schema,
    teamMember: Teams.TeamMember.schema,
    sellerLocation: Sellers.Location.schema,
    sellerDevice: Device.schema,
  });
}
